import React, { useState } from "react";
import { Button } from "@react-md/button";
import { useForm } from "react-hook-form";
import axios from "axios";
import Thanks from "./thanks";

import "./style.scss";

export default function Form() {
  const scrollTop = () => {
    const element = document.getElementById("___gatsby");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    axios
      .post(process.env.GATSBY_FORM_URL, data)
      .then(reset({ name: "", email: "", message: "" }))
      .then(scrollTop())
      .then(setShow(true))
      .catch((error) => console.log(error.message));
  };

  const handle = () => {
    setShow(false);
  };

  return (
    <>
      <Thanks show={show} handle={handle} />
      <div className="form-box">
        <h2>Send us a message</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Name field */}
          <div className="form-field">
            <input
              type="text"
              name="name"
              id="name"
              placeholder=" Your Name & Surname*"
              className="input-text"
              {...register("name", { required: true })}
            />
            <label htmlFor="name" className="label-helper">
              Name {"&"} Surname
            </label>
            {errors.name && errors.name.type === "required" && (
              <p className="errorMsg">Name is required.</p>
            )}
          </div>{" "}
          {/* Email Field */}
          <div className="form-field">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Your Email*"
              className="input-text"
              {...register("email", {
                required: true,
                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
              })}
            />
            <label htmlFor="email" className="label-helper">
              Email
            </label>
            {errors.email && errors.email.type === "required" && (
              <p className="errorMsg">Email is required.</p>
            )}
            {errors.email && errors.email.type === "pattern" && (
              <p className="errorMsg">Email is not valid.</p>
            )}
          </div>
          {/* Message Field */}
          <div className="form-field">
            <label htmlFor="message" className="message-label">
              Message*
            </label>
            <textarea
              name="message"
              id="message"
              className="text"
              {...register("message", { required: true })}
            />
            {errors.message && errors.message.type === "required" && (
              <p className="errorMsg">Message is required.</p>
            )}
          </div>{" "}
          <Button type="submit" themeType="contained" className="submit-btn">
            CONTACT US
          </Button>
        </form>
      </div>
    </>
  );
}