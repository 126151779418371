import React from "react";
import { Button } from "@react-md/button";
import "./style.scss";

export default function thanks(props) {
  return props.show ? (
    <div className="thanks">
      <div>
        {" "}
        <p>Thanks for contacting us</p>
        <Button onClick={props.handle} themeType="contained" className="btn btn-thanks">
          Click To Continue
        </Button>
      </div>
    </div>
  ) : null;
}
