import { RichText } from "prismic-reactjs"
import React from "react"
import Form from "./form"
import "./style.scss"

export default function ContactUs({ data }) {
  return (
    <section className="contactUs">
      <div className="container">
        <div className="contact-details">
          <RichText render={data.title} />
          <RichText render={data.description} />

          {/* <div className="detail-block">
            <p className="detail-block-title">Phone:</p>
            <a href="tel:{data.contact_no_1}">
              <RichText render={data.contact_no_1} />
            </a>
            <a href="tel:{data.contact_no_1}">
              <RichText render={data.contact_no_1} />
            </a>
          </div> */}

          <div className="detail-block">
            <p className="detail-block-title">Email:</p>
            <a href="mailto:{data.email_id}">
              {" "}
              <RichText render={data.email_id} />
            </a>
          </div>

          <div className="detail-block">
            <p className="detail-block-title">Address:</p>
            <RichText render={data.address} />
          </div>
        </div>
        <Form />
      </div>
    </section>
  )
}
