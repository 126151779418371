import React from "react";

import Layout from "../../components/layout";
import ContactUs from "../../components/contact";
import { graphql } from "gatsby";

export default function Contact(props) {
  if (!props.data) return null;
  const data = props.data.allPrismicContact.edges[0].node.dataRaw;

  return (
    <Layout>
      <ContactUs data={data} />
    </Layout>
  );
}

export const contact = graphql`
  query contact {
    allPrismicContact {
      edges {
        node {
          dataRaw
        }
      }
    }
  }
`;
